import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import parse from 'html-react-parser';
import React, { useState } from "react";
import makeZapierRequest from '../../../utils/zapierRequests';
import zapiers from '../../../utils/zapiers';
import * as s from "./Form.module.css";

const Form = ({ block, pageTitle }) => {
  const bp = useBreakpoint();

  const [activeStep, setActiveStep] = useState(0);
  const handleNextStepClick = (index) => {
    if (isStepValid(index)) {
      if (1 == index) {
        submitForm(formData);
      } else {
        setActiveStep(index + 1);
      }
    }
  }

  const defaultFormData = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    companyWebsite: '',
    jobTitle: '',
    revenue: '',
    plannedMonthlyBudget: '',
    services: '',
  };
  const defaultErrorData = {
    firstName: false,
    lastName: false,
    email: false,
    company: false,
    companyWebsite: false,
    jobTitle: false,
    revenue: false,
    plannedMonthlyBudget: false,
    services: false,
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultErrorData);

  const handleInputChange = (name, value) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  }

  const isStepValid = (index) => {
    let isValid = true;
    let newErrors = { ...formErrors };
    switch (index) {
      case 0:
        if (!formData?.firstName?.length) {
          isValid = false;
          newErrors.firstName = 'Required Field';
        } else {
          newErrors.firstName = false;
        }

        if (!formData?.lastName?.length) {
          isValid = false;
          newErrors.lastName = 'Required Field';
        } else {
          newErrors.lastName = false;
        }

        if (!isEmailValid(formData?.email)) {
          isValid = false;
          newErrors.email = 'Please enter a valid Email';
        } else {
          newErrors.email = false;
        }

        if (!formData?.companyWebsite?.length) {
          isValid = false;
          newErrors.companyWebsite = 'Required Field';
        } else {
          newErrors.companyWebsite = false;
        }

        if (!formData?.company?.length) {
          isValid = false;
          newErrors.company = 'Required Field';
        } else {
          newErrors.company = false;
        }
        break;
      case 1:
        if (!formData?.jobTitle?.length) {
          isValid = false;
          newErrors.jobTitle = 'Required Field';
        } else {
          newErrors.jobTitle = false;
        }
        if (!formData?.revenue?.length) {
          isValid = false;
          newErrors.revenue = 'Required Field';
        } else {
          newErrors.revenue = false;
        }
        if (!formData?.plannedMonthlyBudget?.length) {
          isValid = false;
          newErrors.plannedMonthlyBudget = 'Required Field';
        } else {
          newErrors.plannedMonthlyBudget = false;
        }
        if (!formData?.services?.length) {
          isValid = false;
          newErrors.services = 'Required Field';
        } else {
          newErrors.services = false;
        }
        break;

      default:
        break;
    }

    setFormErrors({ ...newErrors });
    return isValid;
  }

  const [messageSent, setMessageSent] = useState(false);
  const submitForm = () => {
    setMessageSent(true);
    let payload = {
      event: 'formSubmit',
      type: zapiers.locationForm.type,
      pageTitle: pageTitle,
      ...formData
    };

    makeZapierRequest(payload, block?.webhookUrl);
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  return (
    <>
      <div className={`${s.formBlock}`}>

        <div className="row justify-content-between">
          <div className="col-12">
            <div>
              {!!block?.title && <h2 className={`${s.title}`}>{parse(block?.title)}</h2>}
              {!!block?.description && <div className={`${s.description}`}>{parse(block?.description)}</div>}
            </div>
            <form action="" className={`${s.form} row`}>
              {activeStep == 0 && <>
                <div className={`${s.inputLabel} col-12 col-lg-6`}>
                  <label htmlFor="firstName">First Name*</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData?.firstName}
                    onChange={e => handleInputChange('firstName', e.target.value)}
                    placeholder="Your First Name" />
                  {false !== formErrors?.firstName && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.firstName}</span>}
                </div>
                <div className={`${s.inputLabel} col-12 col-lg-6`}>
                  <label htmlFor="lastName">Last Name*</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData?.lastName}
                    onChange={e => handleInputChange('lastName', e.target.value)}
                    placeholder="Your Last Name" />
                  {false !== formErrors?.lastName && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.lastName}</span>}
                </div>
                <div className={`${s.inputLabel} w-100 col-12`}>
                  <label htmlFor="email">Your Email*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData?.email}
                    onChange={e => handleInputChange('email', e.target.value)}
                    placeholder="Your Email" />
                  {false !== formErrors?.email && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.email}</span>}
                </div>
                <div className={`${s.inputLabel} col-12 col-lg-6`}>
                  <label htmlFor="copm-website">Company Website*</label>
                  <input
                    type="text"
                    value={formData?.companyWebsite}
                    onChange={e => handleInputChange('companyWebsite', e.target.value)}
                    placeholder="Your Company Website" />
                  {false !== formErrors?.companyWebsite && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.companyWebsite}</span>}
                </div>
                <div className={`${s.inputLabel} col-12 col-lg-6`}>
                  <label htmlFor="comp-name">Company Name*</label>
                  <input
                    type="text"
                    name="company"
                    value={formData?.company}
                    onChange={e => handleInputChange('company', e.target.value)}
                    placeholder="Your Company Name" />
                  {false !== formErrors?.company && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.company}</span>}
                </div>
              </>}
              {activeStep == 1 && <>
                <div className={`${s.inputLabel} col-12 col-lg-6`}>
                  <label htmlFor="jobTitle">Job Title*</label>
                  <input
                    type="text"
                    name="jobTitle"
                    value={formData?.jobTitle}
                    onChange={e => handleInputChange('jobTitle', e.target.value)}
                    placeholder="CEO" />
                  {false !== formErrors?.jobTitle && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.jobTitle}</span>}
                </div>
                <div className={`${s.inputLabel} col-12 col-lg-6`}>
                  <label htmlFor="revenue">Last Year's Revenue*</label>
                  <input
                    type="text"
                    name="revenue"
                    value={formData?.revenue}
                    onChange={e => handleInputChange('revenue', e.target.value)}
                    placeholder="$ 1mln" />
                  {false !== formErrors?.revenue && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.revenue}</span>}
                </div>
                <div className={`${s.inputLabel} w-100 col-12`}>
                  <label htmlFor="plannedMonthlyBudget">What is the current planned monthly budget for Marketing efforts?*</label>
                  <input
                    type="text"
                    name="plannedMonthlyBudget"
                    value={formData?.plannedMonthlyBudget}
                    onChange={e => handleInputChange('plannedMonthlyBudget', e.target.value)}
                    placeholder="Budget" />
                  {false !== formErrors?.plannedMonthlyBudget && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.plannedMonthlyBudget}</span>}
                </div>
                <div className={`${s.inputLabel} w-100 col-12`}>
                  <label htmlFor="services">What are the main services you are interested in?*</label>
                  <input
                    type="text"
                    name="services"
                    value={formData?.services}
                    onChange={e => handleInputChange('services', e.target.value)}
                    placeholder="Services" />
                  {false !== formErrors?.services && <span className="invalid-feedback position-absolute d-block" style={{ bottom: '-20px', color: '#FF593A' }}>{formErrors?.services}</span>}
                </div>
              </>}
              <div className={`col-12 d-flex justify-content-center w-100 ${s.stepMarkers}`}>
                <div className={`${s.stepMarker} ${activeStep == 0 ? s.active : s.cpointer}`} onClick={() => { setActiveStep(0) }}></div>
                <div className={`${s.stepMarker} ${activeStep == 1 ? s.active : ''}`} ></div>
              </div>
              <div className='col-12 d-flex justify-content-center w-100'>
                {activeStep == 0 && <input type="button" value="Next Step" onClick={() => { handleNextStepClick(0) }} className={`${s.formSubmit} `} />}
                {activeStep == 1 && <input type="button" value="Submit" onClick={() => { handleNextStepClick(1) }} className={`${s.formSubmit} `} />}
              </div>
              {messageSent && <div className={`${s.thankYou} col-12`}>
                {!!block?.thankYouText ? parse(block?.thankYouText) : 'Thank you. Your request has been sent.'}
              </div>}
            </form>

          </div>
        </div>

      </div>
    </>
  );
};

export default Form;
