// extracted by mini-css-extract-plugin
export var active = "Form-module--active--463f5";
export var cpointer = "Form-module--cpointer--b7dd5";
export var description = "Form-module--description--30f07";
export var form = "Form-module--form--32982";
export var formBlock = "Form-module--formBlock--5eb9b";
export var formSubmit = "Form-module--formSubmit--c2dff";
export var inputLabel = "Form-module--inputLabel--251ec";
export var stepMarker = "Form-module--stepMarker--88f51";
export var stepMarkers = "Form-module--stepMarkers--b055d";
export var thankYou = "Form-module--thankYou--59ddb";
export var title = "Form-module--title--e9171";